<template>
    <div class="redeem-gift-card-tutorial">
        <div class="tutorial-header">
            <h1>Thanks for Choosing Stark Shopper - How to Use Your Gift Card</h1>
            <button class="close-tutorial-btn" @click="$emit('close')">×</button>
        </div>
        
        <div class="tutorial-content">
            <div class="tutorial-steps">
                <div class="step" v-for="(step, index) in steps" :key="index" :class="{ active: currentStep === index }">
                    <div class="step-number">{{ index + 1 }}</div>
                    <div class="step-content">
                        <h3>{{ step.title }}</h3>
                        <p>{{ step.description }}</p>
                    </div>
                </div>
            </div>
            
            <div class="tutorial-visual">
                <!-- Mock Gift Card for Demonstration -->
                <div class="mock-gift-card" :class="{ 'is-flipped': isCardFlipped }">
                    <!-- Front of Card -->
                    <div class="mock-gift-card-front">
                        <div class="mock-gift-card-content">
                            <div class="mock-gift-card-header">
                                <div class="mock-logo-container">
                                    <img src="@/assets/logo-2.png" alt="Starkshoper Logo" class="mock-logo">
                                </div>
                                <div class="mock-order-info">
                                    <div class="mock-order-number">Order number: 123456789</div>
                                    <div class="mock-order-date">Order date: January 1, 2023</div>
                                    <div class="mock-order-total">Order Total: $25.00</div>
                                </div>
                            </div>
                            
                            <div class="mock-business-info">
                                <div class="mock-business-name">Sample Business</div>
                                <div class="mock-gift-card-title">$20 Worth of Food & Drinks</div>
                            </div>
                            
                            <div class="mock-amount-container">
                                <div class="mock-amount-label">Amount:</div>
                                <div class="mock-amount-value">$20 Worth of Food & Drinks</div>
                            </div>
                            
                            <div class="mock-flip-circle" :class="{ 'highlight': currentStep === 1 }">
                                <span class="mock-arrow-icon">→</span>
                            </div>
                        </div>
                    </div>

                    <!-- Back of Card -->
                    <div class="mock-gift-card-back">
                        <div class="mock-barcode-card">
                            <div class="mock-barcode-title">$20 Worth of Food & Drinks</div>
                            <div class="mock-barcode-container">
                                <svg class="mock-barcode" ref="mockBarcode"></svg>
                                <div class="mock-barcode-number">1234567891234567</div>
                            </div>
                            <div class="mock-button-row">
                                <button class="mock-confirm-use-button" :class="{ 'highlight': currentStep === 2 }">
                                    Confirm Use
                                </button>
                                <button class="mock-details-button" :class="{ 'highlight': currentStep === 3 }">
                                    Gift Card Details
                                </button>
                                <button class="mock-close-button">
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <!-- Mobile Active Step -->
            <div class="mobile-active-step">
                <div class="step-content" v-if="currentStep < steps.length">
                    <h3>{{ steps[currentStep].title }}</h3>
                    <p>{{ steps[currentStep].description }}</p>
                </div>
            </div>
        </div>
        
        <div class="tutorial-navigation">
            <button @click="prevStep" :disabled="currentStep === 0">Previous</button>
            <div class="step-indicators">
                <span v-for="(step, index) in steps" :key="index" 
                      class="step-indicator" 
                      :class="{ active: currentStep === index }"></span>
            </div>
            <div class="next-button-container">
                <div v-if="currentStep < steps.length - 1 && showNextHint" class="next-hint">
                    Click Next to continue <span class="arrow-down">↓</span>
                </div>
                <button 
                    @click="currentStep === steps.length - 1 ? $emit('close') : nextStep()" 
                    :class="{ 
                        'done-btn': currentStep === steps.length - 1,
                        'pulse-attention': showNextHint && currentStep === 0
                    }">
                    {{ currentStep === steps.length - 1 ? 'Done' : 'Next' }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import JsBarcode from 'jsbarcode';

export default {
    name: "RedeemGiftCardTutorial",
    data() {
        return {
            currentStep: 0,
            isCardFlipped: false,
            showNextHint: true,
            steps: [
                {
                    title: "Find Your Gift Card",
                    description: "Locate your gift card in the list below. Each card shows the business name and value. The locations know the value if you do not see that directly in the amount."
                },
                {
                    title: "Flip the Card",
                    description: "Click on the card or the arrow icon to flip the card and reveal the barcode."
                },
                {
                    title: "Redeem at the Location of Purchase",
                    description: "When you're at the location of purchase, click 'Confirm Use' to redeem your gift card. Please verify with an employee before clicking confirm use."
                },
                {
                    title: "View Details",
                    description: "Click 'Gift Card Details' to view fine print and additional information about the gift card."
                },
                {
                    title: "You're All Set!",
                    description: "Once redeemed, the gift card will be marked as used and can't be used again or refunded."
                }
            ]
        };
    },
    watch: {
        currentStep(newStep) {
            // Flip the card when reaching step 2 (index 1)
            if (newStep === 1) {
                setTimeout(() => {
                    this.isCardFlipped = true;
                }, 500);
            } else if (newStep === 0) {
                this.isCardFlipped = false;
            }
        },
        isCardFlipped(newVal) {
            if (newVal) {
                this.$nextTick(() => {
                    this.generateMockBarcode();
                });
            }
        }
    },
    mounted() {
        // Generate barcode on initial mount if card is flipped
        if (this.isCardFlipped) {
            this.$nextTick(() => {
                this.generateMockBarcode();
            });
        }
        
        // Auto-hide the next hint after 10 seconds
        setTimeout(() => {
            this.showNextHint = false;
        }, 10000);
    },
    methods: {
        nextStep() {
            if (this.currentStep < this.steps.length - 1) {
                this.currentStep++;
                // Hide the hint after first click
                this.showNextHint = false;
            }
        },
        prevStep() {
            if (this.currentStep > 0) {
                this.currentStep--;
            }
        },
        generateMockBarcode() {
            const barcodeElement = this.$refs.mockBarcode;
            if (barcodeElement) {
                JsBarcode(barcodeElement, "1234567891234567", {
                    format: "CODE128",
                    displayValue: false,
                    fontSize: 18,
                    width: 2,
                    height: 80,
                    margin: 0
                });
            }
        }
    }
};
</script>

<style scoped>
.redeem-gift-card-tutorial {
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
    width: 90%;
    max-width: 900px;
    max-height: 90vh;
    overflow-y: auto;
    padding: 0;
    position: relative;
    display: flex;
    flex-direction: column;
}

.tutorial-header {
    padding: 20px;
    background-color: #4C6B30;
    color: white;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.tutorial-header h1 {
    margin: 0;
    color: white;
    font-size: 24px;
}

.close-tutorial-btn {
    background: transparent;
    border: none;
    color: white;
    font-size: 28px;
    cursor: pointer;
    line-height: 1;
}

.tutorial-content {
    display: flex;
    padding: 20px;
    min-height: 400px;
}

.tutorial-steps {
    flex: 1;
    padding-right: 20px;
}

.step {
    display: flex;
    margin-bottom: 20px;
    opacity: 0.6;
    transition: opacity 0.3s;
}

.step.active {
    opacity: 1;
}

.step-number {
    width: 30px;
    height: 30px;
    background-color: #4C6B30;
    color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    flex-shrink: 0;
}

.step-content h3 {
    margin-top: 0;
    margin-bottom: 5px;
    color: #333;
}

.step-content p {
    margin-top: 0;
    color: #666;
    line-height: 1.5;
}

.tutorial-visual {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    perspective: 1000px;
    min-height: 360px;
}

/* Mock Gift Card Styles */
.mock-gift-card {
    width: 100%;
    max-width: 400px;
    height: 350px;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    position: relative;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.mock-gift-card.is-flipped {
    transform: rotateY(180deg);
}

.mock-gift-card-front, .mock-gift-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    border-radius: 15px;
    overflow: hidden;
}

.mock-gift-card-front {
    background-color: white;
    display: flex;
    align-items: center;
}

.mock-gift-card-back {
    background-color: white;
    transform: rotateY(180deg);
}

.mock-gift-card-content {
    width: 100%;
    padding: 15px;
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
}

.mock-gift-card-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 15px;
    padding: 5px;
    width: 100%;
}

.mock-logo-container {
    width: 70px;
    height: 70px;
    min-width: 70px;
    margin-right: 15px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mock-logo {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.mock-order-info {
    font-size: 14px;
    color: #666;
    padding: 5px 10px;
    flex: 1;
    text-align: right;
    line-height: 1.0;
}

.mock-order-number, .mock-order-date, .mock-order-total {
    margin-bottom: 8px;
    padding: 2px 0;
}

.mock-business-info {
    margin-bottom: 8px;
    text-align: center;
    align-self: center;
}

.mock-business-name {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 5px;
    color: #333;
}

.mock-gift-card-title {
    font-size: 18px;
    color: #666;
}

.mock-amount-container {
    display: flex;
    margin-bottom: 8px;
    justify-content: center;
    align-self: center;
}

.mock-amount-label {
    font-weight: bold;
    margin-right: 5px;
}

.mock-amount-value {
    color: #4C6B30;
}

.mock-flip-circle {
    position: relative;
    width: 40px;
    height: 40px;
    background-color: white;
    border: 2px solid #ddd;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    align-self: center;
    margin: 8px auto 10px;
}

.mock-flip-circle.highlight {
    border: 2px solid #FFC107;
    animation: pulse 1.5s infinite;
}

.mock-arrow-icon {
    font-size: 24px;
    color: #333;
}

/* Mock Card Back Specific Adjustments */
.mock-barcode-card {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 15px;
    text-align: center;
}

.mock-barcode-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
}

.mock-barcode-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 120px;
    margin-bottom: 0;
}

.mock-barcode {
    width: 80%;
    height: 80px;
}

.mock-barcode-number {
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 0;
}

.mock-button-row {
    display: flex;
    justify-content: space-between;
    gap: 8px;
    margin-top: 20px;
}

.mock-confirm-use-button, .mock-details-button, .mock-close-button {
    flex: 1;
    padding: 8px 5px;
    border-radius: 5px;
    font-size: 13px;
    border: none;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1.2;
}

.mock-confirm-use-button {
    background-color: #4C6B30;
    color: white;
}

.mock-details-button {
    background-color: #6E9F49;
    color: white;
}

.mock-close-button {
    background-color: #dc3545;
    color: white;
}

.mock-confirm-use-button.highlight, .mock-details-button.highlight {
    border: 2px solid #FFC107;
    animation: pulse 1.5s infinite;
}

.tutorial-navigation {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #eee;
    position: relative;
}

.next-button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 1;
}

.next-hint {
    position: absolute;
    bottom: 100%;
    font-size: 14px;
    background-color: #FFC107;
    color: #333;
    padding: 5px 10px;
    border-radius: 5px;
    margin-bottom: 8px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    font-weight: bold;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    animation: fadeIn 0.5s ease-in-out;
    max-width: 160px;
    width: auto;
    line-height: 1.2;
}

.arrow-down {
    font-size: 18px;
    animation: bounce 1s infinite;
    display: inline-block;
}

.tutorial-navigation button {
    padding: 10px 20px;
    background-color: #4C6B30;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.tutorial-navigation button.pulse-attention {
    animation: pulseButton 2s infinite;
}

.tutorial-navigation button.done-btn {
    background-color: #6E9F49;
    font-weight: bold;
    box-shadow: 0 0 8px rgba(110, 159, 73, 0.6);
    padding: 10px 30px;
}

.tutorial-navigation button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.step-indicators {
    display: flex;
    gap: 8px;
}

.step-indicator {
    width: 10px;
    height: 10px;
    background-color: #ddd;
    border-radius: 50%;
}

.step-indicator.active {
    background-color: #4C6B30;
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(255, 193, 7, 0.7);
    }
    70% {
        box-shadow: 0 0 0 10px rgba(255, 193, 7, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(255, 193, 7, 0);
    }
}

@keyframes fadeIn {
    from { opacity: 0; transform: translateY(10px); }
    to { opacity: 1; transform: translateY(0); }
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% { transform: translateY(0); }
    40% { transform: translateY(5px); }
    60% { transform: translateY(3px); }
}

@keyframes pulseButton {
    0% { transform: scale(1); box-shadow: 0 0 0 0 rgba(76, 107, 48, 0.7); }
    70% { transform: scale(1.05); box-shadow: 0 0 0 10px rgba(76, 107, 48, 0); }
    100% { transform: scale(1); box-shadow: 0 0 0 0 rgba(76, 107, 48, 0); }
}

@media (max-width: 768px) {
    .tutorial-content {
        flex-direction: column;
        padding: 15px;
        min-height: auto;
    }
    
    .tutorial-visual {
        order: -1;
        min-height: 350px;
        margin-bottom: 20px;
        width: 100%;
        align-items: center;
    }
    
    .tutorial-steps {
        padding-right: 0;
        padding-top: 0;
        width: 100%;
    }
    
    .mock-gift-card {
        margin: 0 auto 15px;
        max-width: 320px;
        height: 320px;
    }
    
    .mock-barcode-container {
        height: 100px;
    }
    
    .mock-button-row {
        flex-direction: row;
        flex-wrap: wrap;
        gap: 5px;
        margin-top: 10px;
    }
    
    .mock-confirm-use-button, .mock-details-button, .mock-close-button {
        margin-bottom: 5px;
        padding: 6px 4px;
        font-size: 12px;
    }
    
    .next-hint {
        position: absolute;
        right: 0;
        bottom: 100%;
        font-size: 12px;
        padding: 4px 8px;
    }
    
    .step {
        margin-bottom: 15px;
    }
    
    .step-content h3 {
        font-size: 16px;
    }
    
    .step-content p {
        font-size: 14px;
    }
    
    .mobile-active-step {
        display: block;
        padding: 15px;
        background-color: #f9f9f9;
        border-radius: 10px;
        margin-top: 20px;
    }
}

@media (max-width: 480px) {
    .redeem-gift-card-tutorial {
        width: 95%;
        max-height: 95vh;
    }
    
    .tutorial-header h1 {
        font-size: 20px;
    }
    
    .tutorial-content {
        padding: 10px;
    }
    
    .mock-gift-card {
        height: 280px;
        max-width: 280px;
    }
    
    .mock-gift-card-header {
        margin-bottom: 10px;
    }
    
    .mock-logo-container {
        width: 50px;
        height: 50px;
        min-width: 50px;
    }
    
    .mock-business-name {
        font-size: 18px;
    }
    
    .mock-gift-card-title {
        font-size: 16px;
    }
    
    .mock-barcode-container {
        height: 80px;
    }
    
    .mock-barcode {
        height: 60px;
    }
    
    .mock-button-row {
        margin-top: 10px;
    }
    
    .mock-confirm-use-button, .mock-details-button, .mock-close-button {
        height: 35px;
        font-size: 11px;
    }
    
    .tutorial-navigation {
        padding: 12px 8px;
        flex-wrap: wrap;
        gap: 10px;
        justify-content: center;
    }
    
    .step-indicators {
        order: 3;
        width: 100%;
        justify-content: center;
        margin-top: 10px;
    }
    
    .tutorial-navigation button {
        padding: 8px 15px;
        font-size: 14px;
    }
    
    .next-button-container {
        position: relative;
    }
    
    .next-hint {
        right: 0;
        max-width: 120px;
        font-size: 11px;
    }
}

@media (max-width: 360px) {
    .mock-gift-card {
        height: 250px;
        max-width: 250px;
    }
    
    .tutorial-navigation {
        padding: 10px 5px;
    }
    
    .tutorial-navigation button {
        padding: 8px 12px;
        font-size: 13px;
    }
}

/* New mobile layout styles */
.mobile-active-step {
    display: none;
}

@media (max-width: 768px) {
    .tutorial-steps {
        display: none; /* Hide all steps on mobile */
    }
    
    .mobile-active-step {
        display: block;
        margin-top: 20px;
        padding: 15px;
        background-color: #f9f9f9;
        border-radius: 8px;
        box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    }
    
    .mobile-active-step .step-content h3 {
        font-size: 18px;
        margin-top: 0;
        color: #4C6B30;
    }
    
    .mobile-active-step .step-content p {
        font-size: 15px;
        line-height: 1.5;
    }
}

@media (max-width: 480px) {
    .mobile-active-step {
        padding: 12px;
    }
    
    .mobile-active-step .step-content h3 {
        font-size: 16px;
    }
    
    .mobile-active-step .step-content p {
        font-size: 14px;
    }
}
</style>